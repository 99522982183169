import { Hero, KeyFeatures } from "../../components";
import "./LandingPage.scss";

export const LandingPage = () => {
  return (
    <>
      <Hero />
      <br />
      <KeyFeatures />
    </>
  );
};
